import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import { FormattedMessage } from "react-intl"
import Layout from "../components/Layout"
import TechTag from "../components/TechTag"
import AppDevThumbnail from "../components/thumbnails/AppDevThumbnail"
import UiUxThumbnail from "../components/thumbnails/UiUxThumbnail"
import WebFrontendThumbnail from "../components/thumbnails/WebFrontendThumbnail"
import WebBackendThumbnail from "../components/thumbnails/WebBackendThumbnail"

import DesignCirclePartImage from "../images/mask@3x.png"
import DesignCornerImage from "../images/rectangle-md.svg"
import BatisOvalImage from "../images/batis-oval.svg"
import BatisStudyImage from "../images/batis-study-blueprint.svg"
import BatisLogoImage from "../images/batis-tu-logo.svg"
import BatisCirclePartImage from "../images/batis-design-circle.svg"
import BatisFunctionIcon1 from "../images/funct-1.png"
import BatisFunctionIcon2 from "../images/funct-2.png"
import BatisFunctionIcon3 from "../images/funct-3.png"
import BatisFunctionIcon4 from "../images/funct-4.png"
import BatisFunctionIcon5 from "../images/funct-5.png"
import BatisFunctionIcon6 from "../images/funct-6.png"
import BatisFunctionIcon7 from "../images/funct-7.png"

const CaseStudyTemplate = ({
  title,
  mainDescription,
  listImage,
  challengeTitle,
  challengeDescription,
  challengeAchievement,
  challengeImage,
  studyTitle,
  studyDescription,
  functionalityTitle,
  functionalityDescription,
  functionalityDescription2,
  functionalityImage,
  functionalityItems,
  communicationTitle,
  communicationDescription,
  communicationDescription2,
  communicationImage,
  caseStudyTitle,
  helmet,
}) => (
  <div>
    {helmet || ""}

    {/* FOLD BLOCK*/}
    <div className="relative lg:flex container mx-auto py-24 pb-4 px-10 lg:px-32">
      <img
        src={DesignCirclePartImage}
        alt=""
        className="absolute w-24 transform rotate-180 hidden md:block"
        style={{ left: "45%", top: "5px" }}
      />
      <img
        src={DesignCirclePartImage}
        alt=""
        className="absolute w-48 pt-6 right-8 z-10 bottom-4 hidden md:block"
      />
      <div className="lg:w-1/2">
        <h1 className="font-semibold text-6xl tracking-wide text-gray-900 leading-none lg:pr-48">
          {title}
        </h1>
        <p className="font-light text-lg text-gray-500 mt-10 lg:pr-12">
          {mainDescription}
        </p>
        <p className="mt-10 pb-8 md:pb-24">
          <TechTag>React Native</TechTag>
          <TechTag>GraphQL</TechTag>
          <TechTag>Node.js</TechTag>
        </p>
      </div>
      <div className="md:w-3/5 lg:mt-0 sm:w-3/4 md:pl-24">
        <img
          src={listImage.childImageSharp.fixed.src}
          className="mx-auto w-7/8"
        />
      </div>
    </div>

    {/* CHALLENGE BLOCK */}
    <div
      className="relative overflow-hidden"
      style={{ backgroundColor: "#F9F9F9" }}
    >
      <img
        src={BatisOvalImage}
        className="absolute invisible md:visible md:top-0 left-0"
        alt=""
      />
      <div className="relative container mx-auto py-12 px-10 lg:py-20 lg:pr-32 lg:pl-20 xl:py-28">
        <div className="sm:mb-12 md:mb-0 md:grid md:grid-cols-10">
          <div className="flex col-start-6 col-span-5 order-2 md:items-center">
            <span className="flex flex-shrink-0 w-4 h-4 bg-green-200 mt-2 mr-2 md:mt-0 md:mr-6 lg:mr-10 lg:w-6 lg:h-6"></span>
            <h2 className="relative font-semibold text-xl lg:text-3xl">
              {challengeTitle}
            </h2>
          </div>
          <div className="relative row-span-2 col-span-5 order-1 mt-8 mb-2 py-4 md:my-auto xl:col-span-4">
            <img src={challengeImage.childImageSharp.fixed.src} alt="" />
            <img
              src={BatisLogoImage}
              alt=""
              className="absolute w-16 top-0 left-8 md:w-20 md:left-12 lg:w-24"
            />
          </div>
          <div className="col-start-6 col-span-5 order-3">
            <div className="md:pt-8 md:pl-4 lg:pl-16">
              <p className="relative text-sm text-gray-500 leading-6 lg:text-lg lg:leading-7">
                {challengeDescription}
              </p>
              <p className="relative text-xs leading-6 mt-20 ml-12 lg:text-sm lg:leading-7 lg:ml-16 lg:mr-8">
                <img
                  src={DesignCornerImage}
                  alt=""
                  className="absolute w-12 opacity-50 -top-12 -left-12 lg:-top-12 lg:-left-16"
                />
                <b>{challengeAchievement}</b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* STUDY BLOCK */}
    <div className="relative container px-10 mx-auto py-12 md:pb-0 lg:pt-24 lg:pb-0 lg:px-32">
      <div className="mb-12 md:grid md:grid-cols-2 md:mb-0 lg:grid-cols-5 xl:grid-cols-2">
        <div className="flex md:items-center">
          <span className="flex flex-shrink-0 w-4 h-4 bg-green-200 mt-2 mr-2 md:mt-0 md:mr-6 lg:mr-10 lg:w-6 lg:h-6"></span>
          <h2 className="relative font-semibold text-xl lg:text-3xl">
            {studyTitle}
          </h2>
        </div>
        <div className="relative row-span-2 md:py-auto lg:col-span-2 lg:col-start-4 xl:col-start-2 xl:col-span-2">
          <img
            src={BatisStudyImage}
            alt=""
            className="mx-auto lg:absolute xl:-top-12"
          />
        </div>
        <div className="pt-4 sm:pt-8 md:pt-0 md:pr-8 lg:pt-8 lg:px-16 lg:col-span-3 xl:col-span-1">
          <p className="text-sm text-gray-500 leading-6 lg:text-lg lg:leading-7">
            {studyDescription}
          </p>
        </div>
      </div>
    </div>

    {/* FUNCTIONALITY BLOCK PART 1*/}
    <div className="relative container mx-auto md:px-10 lg:px-32 lg:pr-32 lg:pl-20">
      <div className="md:grid md:grid-cols-5 md:grid-rows-1 md:relative md:-bottom-32 lg:-bottom-48">
        <div className="col-start-1 col-end-4 col-span-3 row-start-1 lg:mt-10">
          <div className="relative mx-8 -mb-32">
            <img
              src={functionalityImage.childImageSharp.fixed.src}
              alt=""
              className="z-20 relative px-4 mx-auto lg:w-5/6"
            />
            <div
              className="absolute rounded-full h-64 w-64 -top-8 md:invisible"
              style={{ backgroundColor: "#F9F9F9" }}
            ></div>
            <img
              src={BatisCirclePartImage}
              alt=""
              className="z-10 absolute w-12 bottom-6 md:w-24 md:bottom-2 md:-left-4 lg:bottom-0 lg:left-2 xl:bottom-4 xl:left-6"
            />
            <span className="absolute w-6 h-6 bg-green-200 opacity-50 top-0 left-4 md:w-10 md:h-10 lg:w-12 lg:h-12"></span>
          </div>
        </div>
        <div className="col-start-3 col-end-6 col-span-3 row-start-1 md:ml-8 md:mt-20 lg:mt-32 xl:mt-40 xl:ml-12">
          <div
            className="hidden md:flex absolute top-0 -right-8 lg:-right-32 lg:top-12 xl:top-8 xl:-right-12"
            style={{
              height: "500px",
              width: "500px",
              backgroundColor: "#D8D8D8",
              borderRadius: "50%",
              opacity: "15%",
            }}
          ></div>
          <div className="relative bg-gray-900 px-10 pt-40 pb-12 md:py-8 md:pl-24 md:pr-8 lg:py-12 lg:pr-12 lg:pl-32 xl:pr-16 xl:pl-40">
            <p className="font-semibold text-xs text-white leading-6 lg:font-normal lg:text-md lg:leading-7">
              {functionalityDescription2}
            </p>
            <img
              src={DesignCirclePartImage}
              alt=""
              className="absolute invisible transform rotate-180 w-24 md:visible md:-top-8 md:left-12 lg:w-28 lg:left-24 lg:-top-10"
            />
          </div>
        </div>
      </div>
    </div>

    {/* FUNCTIONALITY BLOCK PART 2 */}
    <div className="" style={{ backgroundColor: "#F9F9F9" }}>
      <div className="relative container mx-auto py-12 px-10 md:pt-40 lg:pb-20 lg:pr-32 lg:pl-20 xl:pb-28">
        <div className="flex col-start-6 col-span-5 order-2 md:items-center md:pt-10 lg:pt-24 xl:pt-48 xl:mt-10">
          <span className="flex flex-shrink-0 w-4 h-4 bg-green-200 mt-2 mr-2 md:mt-0 md:mr-6 lg:mr-10 lg:w-6 lg:h-6"></span>
          <h2 className="relative font-semibold text-xl lg:text-3xl">
            {functionalityTitle}
          </h2>
        </div>
        <div className="pt-4 pb-8 sm:pt-8 lg:pl-16 lg:pr-16">
          <p className="text-sm text-gray-500 leading-6 lg:text-lg lg:leading-7">
            {functionalityDescription}
          </p>
        </div>
        <div className="grid grid-cols-2 md:grid-rows-1 md:grid-cols-4">
          {[
            BatisFunctionIcon1,
            BatisFunctionIcon2,
            BatisFunctionIcon3,
            BatisFunctionIcon4,
          ].map((icon, i) => (
            <div key={i} className="text-gray-700 items-center text-center">
              <img src={icon} alt="" className="mx-auto h-20 mb-4" />
              <h4 className="mb-12 uppercase font-semibold leading-snug text-sm lg:text-lg lg:mb-8">
                {functionalityItems[i]}
              </h4>
            </div>
          ))}
        </div>
        <div className="grid grid-cols-2 md:grid-rows-1 md:grid-cols-3">
          {[BatisFunctionIcon5, BatisFunctionIcon6, BatisFunctionIcon7].map(
            (icon, i) => (
              <div key={i} className="text-gray-700 items-center text-center">
                <img src={icon} alt="" className="mx-auto h-20 mb-4" />
                <h4 className="mb-12 uppercase font-semibold leading-snug text-sm lg:text-lg lg:mb-8">
                  {functionalityItems[i + 4]}
                </h4>
              </div>
            )
          )}
        </div>
        <img
          src={DesignCirclePartImage}
          alt=""
          className="absolute w-24 lg:w-48 right-16 -bottom-8"
        />
      </div>
    </div>

    {/* COMMUNICATION BLOCK */}
    <div className="relative container px-10 mx-auto pt-16 pb-8 lg:pb-12 lg:px-32">
      <div className="flex md:items-center">
        <span className="flex flex-shrink-0 w-4 h-4 bg-green-200 mt-2 mr-2 md:mt-0 md:mr-6 lg:mr-10 lg:w-6 lg:h-6"></span>
        <h2 className="relative font-semibold text-xl lg:text-3xl">
          {communicationTitle}
        </h2>
      </div>
      <div className="sm:mb-12 md:mb-0 md:grid md:grid-cols-7">
        <div className="relative row-span-2 col-span-3 pt-8 pb-4 md:my-auto lg:my-12">
          <img src={communicationImage.childImageSharp.fixed.src} alt="" />
        </div>
        <div className="col-start-5 col-span-3">
          <div className="mt-4 lg:mb-24">
            <p className="font-semibold text-sm leading-6 pb-8 lg:text-lg lg:leading-7 lg:pt-10">
              {communicationDescription}
            </p>
            <p className="text-gray-500 text-sm leading-6 pb-8 lg:text-lg lg:leading-7 lg:pb-0">
              {communicationDescription2}
            </p>
          </div>
        </div>
      </div>
      <div className="flex-row border-solid border-t-12 my-8 border-gray-bg md:flex lg:border-t-16 lg:my-10"></div>
    </div>

    {/* USED TECHNOLOGIES BLOCK */}
    <div className="container mx-auto pb-16 lg:pb-24 px-10 lg:px-32">
      <h3 className="text-center font-semibold text-lg lg:text-2xl mb-12">
        <FormattedMessage
          id="case_study.tech_used"
          values={{ app: caseStudyTitle }}
        />
      </h3>
      <div className="md:grid md:grid-cols-2">
        <AppDevThumbnail />
        <UiUxThumbnail />
        <WebFrontendThumbnail techTitle="GraphQL" />
        <WebBackendThumbnail techTitle="Node.js" />
      </div>
      <div className="flex md:pt-8 justify-center">
        <TechTag>React Native</TechTag>
        <TechTag>GraphQL</TechTag>
        <TechTag>Node.js</TechTag>
      </div>
    </div>
  </div>
)

const BatisCaseStudy = ({ data, location }) => {
  const { markdownRemark: project } = data

  return (
    <Layout location={location}>
      <CaseStudyTemplate
        helmet={
          <Helmet>
            <title>{`${project.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${project.frontmatter.mainDescription}`}
            />
          </Helmet>
        }
        {...project.frontmatter}
      />
    </Layout>
  )
}

export default BatisCaseStudy

export const pageQuery = graphql`
  query batisProjectByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        mainDescription
        listImage {
          childImageSharp {
            fixed(width: 500, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        challengeTitle
        challengeDescription
        challengeAchievement
        challengeImage {
          childImageSharp {
            fixed(width: 700, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        studyTitle
        studyDescription
        functionalityTitle
        functionalityDescription
        functionalityDescription2
        functionalityImage {
          childImageSharp {
            fixed(width: 700, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        functionalityItems
        communicationTitle
        communicationDescription
        communicationDescription2
        communicationImage {
          childImageSharp {
            fixed(width: 600, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        caseStudyTitle
      }
    }
  }
`
