import React from "react"
import BackendLogo from "../../images/services/backend.svg"
import { FormattedMessage } from "react-intl"

const WebBackendThumbnail = ({ techTitle, children, ...props }) => (
  <div {...props}>
    <div className="flex flex-col text-gray-700 items-center text-center px-4 py-2 m-2">
      <img src={BackendLogo} alt="" className="mx-auto h-16 mb-6" />
      <p className="uppercase font-light text-sm whitespace-no-wrap">
        <FormattedMessage id="services.backendDev" />
      </p>
      <h4 className="mb-6 uppercase font-semibold text-xl leading-snug">
        {techTitle}
      </h4>
      {children}
    </div>
  </div>
)

export default WebBackendThumbnail
